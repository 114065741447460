import { db } from '@/main'
import * as fb from '@/firebase'
import router from '@/router'
import firebaseApp from "firebase/app"

export default {
  namespaced: true,

  state: {
    // in App.vue, switch to force reload:
    forceReload: true,
    isRoleFound: false,
    authMode: 'login',  // 'login' or 'register'
    promoCode: '',
    // isSignInWithEmailLink: false,
    verifiedEmail: '',
    isEmailVerified: false, // registrating an user
    // isPrivacyChecked: false,  // registering an user
    // isBeingReg: false,  // registering a user............ need?
    loginTime: '',
    nextReadTime: '',
    userProfile: {}, // logged-in user
    isUserProfileLoaded: false, // control loading sequence
    isUsersLoaded: false, // control loading sequence
    isRolesLoaded: false, // control loading sequence
    isRPCLoaded: false, // control RPC loaded once
    loginErrorMsg: '',
    resetPWErrorMsg: '',
    passwordResetSuccess: false,
    isChecked: false,
    sys_users: [], // all users
    sys_roles: [], // all roles
    userDir: {}, // user, used in Directory
    userAdmin: {}, // user, used in User Admin
    userLocal: {},  // in store to save values from too many tabs
    geoData: {}, // record geoData when user log on, will NOT be saved

    userViewDir: '', // as above
    userViewAdmin: '', // as above
    userViewProfile: '', // as above
    addUserTagAdmin: false, // as above
    isEdited: false, // used in user admin, indicating if it's edited

    fileURL: '', // for avatar upload and render
    errArray: {},  // error message from reg
    loginForm: {} // keeping email and password for registration
  },

  getters: {},

  actions: {
    // signInWithEmailLink ({ commit, dispatch }) {
    //   var email = window.localStorage.getItem('emailForSignIn')
    //   console.log('email: ' + email)
    //   console.log('window.location.href: ' + window.location.href)
    //   console.log('fb.auth.isSignInWithEmailLink(window.location.href): ' + fb.auth.isSignInWithEmailLink(window.location.href))
      
    //   if (fb.auth.isSignInWithEmailLink(window.location.href)) {
    //     console.log('isSignInWithEmailLink is yes')
    //     if (!email) {
    //       email = window.prompt('Please provide your email for confirmation')
    //     }
    //     fb
    //       .auth
    //       .signInWithEmailLink(email, window.location.href)
    //       .then(function (result) {
    //         dispatch('fetchUserProfile', result.user)
    //         commit('SET_LOGINERRORMSG', '')
    //         window.localStorage.removeItem('emailForSignIn')
    //       })
    //       .catch(function (error) {
    //         alert(error)
    //       })
    //   }
    // },
    setAuthMode({ commit }, payload) {
      commit('SET_AUTHMODE', payload)
    },
    setPromoCode({ commit }, payload) {
      commit('SET_PROMOCODE', payload)
    },
    setForceReload({ commit }, payload) {
      commit('SET_FORCERELOAD', payload)
    },
    // setIsBeingReg({ commit }, payload) {
    //   commit('SET_ISBEINGREG', payload)
    // },
    // setIsPrivacyChecked({ commit }, payload) {
    //   commit('SET_ISPRIVACYCHECKED', payload)
    // },
    // setIsSignInWithEmailLink({ commit }, payload) {
    //   commit('SET_ISSIGNINWITHEMAILLINK', payload)
    // },
    setVerifiedEmail({ commit }, payload) {
      commit('SET_VERIFIEDEMAIL', payload)
    },
    setLoginTime({ commit }, payload) {
      commit('SET_LOGINTIME', payload)
    },
    setNextReadTime({ commit }, payload) {
      commit('SET_NEXTREADTIME', payload)
    },
    setUserProfile({ state, commit }, payload) {
      commit('CLEAR_USERSETTINGS')
    },
    setIsEmailVerified({ state, commit }, payload) {
      commit('SET_ISEMAILVERIFIED', payload)
    },
    // Firebase Authenications - Login
    async login({ dispatch, commit }, form) {
      // sign user in
      await fb.auth.signInWithEmailAndPassword(form.email, form.password)
        // eslint-disable-next-line
        .then(function (result) {
          dispatch('fetchUserProfile', result.user)
          // commit('SET_LOGINGFORM', form)
          commit('SET_LOGINERRORMSG', '')
        })
        // eslint-disable-next-line
        .catch(function (error) {
          commit('SET_LOGINERRORMSG', error.message)
        })
    },

    // Firebase Authenications - load Profile
    async fetchUserProfile({ commit }, user) {
      const userProfile = await fb.sys_usersCollection.doc(user.uid).get()
      let userProfileObj = {}
      userProfileObj = userProfile.data()
      userProfileObj.id = userProfile.id

      commit('SET_USERPROFILE', userProfileObj)
      commit('SET_ISUSERPROFILELOADED', true)
      if (router.currentRoute.path === '/login') {
        router.push('/').catch(() => true)
      }
    },

    // Google Authenications - Login
    googleLogin({ state, dispatch, commit }) {
      // sign user in
      let provider = new firebaseApp.auth.GoogleAuthProvider()
      
      fb.auth
        .signInWithPopup(provider)
        .then(function (result) {
          
          // if it is NOT new user...
          if (!result.additionalUserInfo.isNewUser) {
            dispatch('fetchUserProfile', result.user)
          } else {
            // new user is prohibited
            result.user.delete().then(()=>{
              fb.auth.signOut().then(() => {
                alert("Please click on 'Create an account' to Sign Up first")
              })
             })
          }
          commit('SET_LOGINERRORMSG', '')
        })
        // eslint-disable-next-line
        .catch(function (error) {
          commit('SET_LOGINERRORMSG', error.message)
        })
    },

    // Firebase Authenications - Logout
    async logout({ commit }) {
      commit('CLEAR_USERSETTINGS')
      await fb.auth.signOut()
      // firebase.auth().onAuthStateChanged(() => {
        // commit('CLEAR_USERSETTINGS')
        // clear userProfile and redirect to /login
        router.push('/login')
      // })
      // FirebaseAuth.getInstance().signOut();
    },

    // Firebase Authenications - Reset Password
    // Payload is email
    async resetPassword({ dispatch, commit }, payload) {
      await fb.auth.sendPasswordResetEmail(payload)
        // eslint-disable-next-line
        .then(function () {
          dispatch('setIsChecked', true)
          commit('SET_PASSWORDRESETSUCCESS', true)
          commit('SET_RESETPWERRORMSG', '')
        })
        // eslint-disable-next-line
        .catch(function (error) {
          commit('SET_RESETPWERRORMSG', error.message)
        })
    },

    setIsChecked({ commit }, payload) {
      commit('SET_ISCHECKED', payload)
    },

    setPasswordResetSuccess({ commit }) {
      commit('SET_PASSWORDRESETSUCCESS', false)
    },
    
    // Firebase Authenications - From Register Page
    async sendLinkToEmail({ commit }, form) {
      fb.auth
      .sendSignInLinkToEmail(form.email, {
          url: "http://localhost:8080/" + "/?email=" + form.email,
          // url = "$FIREBASE_DEFAULT_DOMAIN" + "/?email=your_email@gmail.com"
          // url: `http://localhost:8080/${form.email}`,   //.......... will be changed
          handleCodeInApp: true
        })
      .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          // window.localStorage.setItem('emailForSignIn', JSON.stringify(form.email));
          window.localStorage.setItem('emailForSignIn', form.email)
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          commit('SET_ERRARRAY', error)
          // ...
        });
    },
    // Sign up from Reg (just have userName and email)
    // i.e. createUserWithEmailAndPassword => sendEmailVerification    
    
    async signUpReg({ commit }, form) {
      // Sign User Up
      const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)
      await fb.sys_usersCollection.doc(user.uid).set({
        userName: form.userName,
        photoURL: '',
        title: '',
        email: form.email,
        mobile: '',
        disabled: false,
        bio: '',
        loc_address1: '',
        loc_address2: '',
        loc_city: '',
        loc_state: '',
        loc_country: '',
        loc_postcode: '',
        siteAdmin: true,       
        teammate: [],
        tourTutorialShown: false,
        tourDesktopEnabled: true,
        tourMobileEnabled: true,
        zChangePW: false,
        zCusId: form.zCusId,
        promoCode: form.promoCode
      })
      commit('ADD_SYS_USERS', form)
      // const actionCodeSettings = {
      //   url: 'http://localhost:8080/verified',
      //   handleCodeInApp: true
      // }
      const actionCodeSettings = {
        url: 'https://app.goalpen.com/' + '?email=' + form.email,
        handleCodeInApp: true
      }
      // url: 'http://localhost:8080/' + '?email=' + form.email,
      fb.auth.currentUser.sendEmailVerification(actionCodeSettings)
        .then(
          () => {
            // payload.pushFunc()
            // commit('setLoading', false)
          }
        )
      // fb.auth.sendSignInLinkToEmail(form.email, actionCodeSettings)
      //   .then(() => {
      //     // The link was successfully sent. Inform the user.
      //     // Save the email locally so you don't need to ask the user for it again
      //     // if they open the link on the same device.
      //     window.localStorage.setItem('emailForSignIn', email);
      //     // ...
      //   })
      //   .catch((error) => {
      //     // var errorCode = error.code;
      //     // var errorMessage = error.message;
      //     // ...
      //   });
    },
    // Firebase Authenications - From  User Admin
    async signUp({ commit }, form) {
      // Sign User Up
      const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)
      await fb.sys_usersCollection.doc(user.uid).set({
        userName: form.userName,
        photoURL: form.photoURL,
        title: form.title,
        email: form.email,
        mobile: form.mobile,
        disabled: false,
        bio: form.bio,
        loc_address1: form.loc_address1,
        loc_address2: form.loc_address2,
        loc_city: form.loc_city,
        loc_state: form.loc_state,
        loc_country: form.loc_country,
        loc_postcode: form.loc_postcode,
        siteAdmin: form.siteAdmin,        
        teammate: form.teammate,
        tourTutorialShown: false,
        tourDesktopEnabled: true,
        tourMobileEnabled: true,
        zChangePW: form.zChangePW,
        zCusId: form.zCusId,
        promoCode: '',
      })
      commit('ADD_SYS_USERS', form)
    },

    // Firebase - Get all Users
    async get_sys_users({ commit }, payload) {
      let snapshot = await db.collection("sys_users")
        .where('zCusId', '==', payload)
        .get();
      let sys_users = [];
      snapshot.forEach(doc => {
        let appData = doc.data()
        appData.id = doc.id
        sys_users.push(appData)
      })
      commit('SET_SYS_USERS', sys_users)
      commit('SET_ISUSERSLOADED', true)
    },
  
    // Firebase - Get all Roles
    async get_sys_roles({ commit }, payload) {
      let snapshot = await db.collection("sys_roles")
        .where('zCusId', '==', payload)
        .get();
      let sys_roles = [];
      snapshot.forEach(doc => {
        let appData = doc.data()
        appData.id = doc.id
        sys_roles.push(appData)
      })
      commit('SET_SYS_ROLES', sys_roles)
      commit('SET_ISROLESLOADED', true)
    },

    async addUserRole({ commit }, payload) {
      await db
      .collection('sys_roles')
      .add({
        roleName: payload.roleName,
        description: payload.description,
        members: payload.members,
        permissions: payload.permissions,
        zCusId: payload.zCusId,
      }).then(function (docRef) {
        payload.id = docRef.id
      })
      commit('SET_SYSROLES', payload)
      commit('ADD_TOSYSROLES', payload)
      // console.log('..... vuex addUserRole DONE')
    },

    async updateUserRole({ commit }, payload) {
      await db
        .collection('sys_roles')
        .doc(payload.id)
        .update({
          roleName: payload.roleName,
          description: payload.description,
          members: payload.members,
          permissions: payload.permissions,
          zCusId: payload.zCusId,
        })
      commit('SET_SYSROLES', payload)
    },

    // Update password
    async updatePassword(payload) {
      // fb.auth()
      //   .signInWithEmailAndPassword('email', 'oldPassword')
      //   .then(function(user) {

            await fb.auth().currentUser.updatePassword(payload.password).then(function(){

                //Do something

            }).catch(function(err){
                //Do something
            });

        // }).catch(function(err){
        //     //Do something
        // });
          },

    // Update user
    async updateUser({ commit }, payload) {
      // console.log('payload.email: ' + payload.email)
      // console.log('payload.disabled: ' + payload.disabled)
      // await fb.auth.updateUser(payload.id, {
      //   email: payload.email,
      //   // disabled: payload.disabled
      // }),
      await db
        .collection('sys_users')
        .doc(payload.id)
        .update({
          userName: payload.userName,
          photoURL: payload.photoURL,
          title: payload.title,
          email: payload.email,
          mobile: payload.mobile,
          disabled: payload.disabled,
          bio: payload.bio,
          loc_address1: payload.loc_address1,
          loc_address2: payload.loc_address2,
          loc_city: payload.loc_city,
          loc_state: payload.loc_state,
          loc_country: payload.loc_country,
          loc_postcode: payload.loc_postcode,
          siteAdmin: payload.siteAdmin,
          teammate: payload.teammate,
          tourTutorialShown: payload.tourTutorialShown,
          tourDesktopEnabled: payload.tourDesktopEnabled,
          tourMobileEnabled: payload.tourMobileEnabled,
          zChangePW: payload.zChangePW,
          zCusId: payload.zCusId,
          promoCode: payload.promoCode
        })
      commit('SET_SYSUSERS', payload)
    },
    // Update user but doesn't touch the db (i.e. Admin's cancel button)
    updateLocalUser({ commit }, payload) {
      commit('SET_SYSUSERS', payload)
    },
    setIsEdited({ commit }, payload) {
      commit('SET_ISEDITED', payload)
    },
    setUserDir({ commit }, payload) {
      commit('SET_USERDIR', payload)
    },
    setUserAdmin({ commit }, payload) {
      commit('SET_USERADMIN', payload)
    },
    setUserLocal({ commit }, payload) {
      commit('SET_USERLOCAL', payload)
    },
    setGeoData({ commit }, payload) {
      commit('SET_GEODATA', payload)
    },
    setUserViewDir({ commit }, payload) {
      commit('SET_USERVIEWDIR', payload)
    },
    setUserViewAdmin({ commit }, payload) {
      commit('SET_USERVIEWADMIN', payload)
    },
    setUserViewProfile({ commit }, payload) {
      commit('SET_USERVIEWPROFILE', payload)
    },
    setAddUserTagAdmin({ commit }, payload) {
      commit('SET_ADDUSERTAGADMIN', payload)
    },
    setFileURL({ commit }, payload) {
      commit('SET_FILEURL', payload)
    },
    setLoginErrorMsg({ commit }, payload) {
      commit('SET_LOGINERRORMSG', payload)
    },
  },

  mutations: {
    SET_AUTHMODE(state, payload) {
      state.authMode = payload
    },
    SET_PROMOCODE(state, payload) {
      state.promoCode = payload
    },
    SET_FORCERELOAD(state, payload) {
      state.forceReload = payload
    },
    SET_ERRARRAY(state, payload) {
      state.errArray = payload
    },
    // SET_ISBEINGREG(state, payload) {
    //   state.isBeingReg = payload
    // },
    SET_ISEMAILVERIFIED(state, payload) {
      state.isEmailVerified = payload
    },
    // SET_ISPRIVACYCHECKED(state, payload) {
    //   state.isPrivacyChecked = payload
    // },
    SET_VERIFIEDEMAIL(state, payload) {
      state.verifiedEmail = payload
    },
    // SET_ISSIGNINWITHEMAILLINK(state, payload) {
    //   state.isSignInWithEmailLink = payload
    // },
    SET_LOGINTIME(state, payload) {
      state.loginTime = payload
      // console.log('state.loginTime: ' + state.loginTime)
    },
    SET_NEXTREADTIME(state, payload) {
      state.nextReadTime = payload
      // console.log('state.nextReadTime: ' + state.nextReadTime)
    },
    SET_ISROLEFOUND(state, payload) {
      state.isRoleFound = payload
    },
    SET_USERPROFILE(state, payload) {
      state.userProfile = payload
    },
    SET_ISUSERPROFILELOADED(state, payload) {
      state.isUserProfileLoaded = payload
    },
    SET_ISUSERSLOADED(state, payload) {
      state.isUsersLoaded = payload
    },
    SET_ISROLESLOADED(state, payload) {
      state.isRolesLoaded = payload
    },
    SET_ISRPCLOADED(state, payload) {
      state.isRPCLoaded = payload
    },
    SET_LOGINERRORMSG(state, loginErrorMsg) {
      state.loginErrorMsg = loginErrorMsg
    },
    SET_RESETPWERRORMSG(state, resetPWErrorMsg) {
      state.resetPWErrorMsg = resetPWErrorMsg
    },
    SET_ISCHECKED(state, isChecked) {
      state.isChecked = isChecked
    },
    SET_PASSWORDRESETSUCCESS(state, passwordResetSuccess) {
      state.passwordResetSuccess = passwordResetSuccess
    },
    ADD_SYS_USERS(state, payload) {
      state.sys_users.push(payload)
    },
    SET_SYS_USERS(state, sys_users) {
      state.sys_users = sys_users
    },
    SET_SYS_ROLES(state, sys_roles) {
      state.sys_roles = sys_roles
    },
    SET_SYSUSERS(state, payload) {
      state.sys_users.forEach(eachUser => {
        if (eachUser.id === payload.id) {
          eachUser.userName = payload.userName
          eachUser.photoURL = payload.photoURL
          eachUser.title = payload.title
          eachUser.email = payload.email
          eachUser.mobile = payload.mobile
          eachUser.disabled = payload.disabled
          eachUser.bio = payload.bio
          eachUser.loc_address1 = payload.loc_address1
          eachUser.loc_address2 = payload.loc_address2
          eachUser.loc_city = payload.loc_city
          eachUser.loc_state = payload.loc_state
          eachUser.loc_country = payload.loc_country
          eachUser.loc_postcode = payload.loc_postcode
          eachUser.siteAdmin = payload.siteAdmin
          eachUser.tourTutorialShown = payload.tourTutorialShown
          eachUser.tourDesktopEnabled = payload.tourDesktopEnabled
          eachUser.tourMobileEnabled = payload.tourMobileEnabled
          eachUser.teammate = payload.teammate
          eachUser.zChangePW = payload.zChangePW
          eachUser.zCusId = payload.zCusId
          eachUser.promoCode = payload.promoCode
        } 
      })
      state.sys_users.update
      // console.log('task.js; task is added with id: ' + state.task.id)
    },
    SET_SYSROLES(state, payload) {
      state.sys_roles.forEach(eachRole => {
        if (eachRole.id === payload.id) {
          eachRole.roleName = payload.roleName
          eachRole.description = payload.description
          eachRole.members = payload.members
          eachRole.permissions = payload.permissions
          eachRole.zCusId = payload.zCusId
        } 
      })
      state.sys_roles.update
      // console.log('task.js; task is added with id: ' + state.task.id)
    },
    ADD_TOSYSROLES(state, payload) {
      let isSysRolesNoteFound = false
      state.sys_roles.forEach(sys_role => {
        if (sys_role.id === payload.id) {
          isSysRolesNoteFound = true
        }
      })
      if (!isSysRolesNoteFound) {
        state.sys_roles.push(payload)
      }
      // state.sys_roles.push(payload)
      // state.sys_roles.update
    },
    SET_ISEDITED(state, isEdited) {
      state.isEdited = isEdited
    },
    SET_USERDIR(state, userDir) {
      state.userDir = userDir
    },
    SET_USERADMIN(state, userAdmin) {
      state.userAdmin = userAdmin
    },
    SET_USERLOCAL(state, userLocal) {
      state.userLocal = userLocal
    },
    SET_GEODATA(state, geoData) {
      state.geoData = geoData
    },
    SET_USERVIEWDIR(state, userViewDir) {
      state.userViewDir = userViewDir
    },
    SET_USERVIEWADMIN(state, userViewAdmin) {
      state.userViewAdmin = userViewAdmin
    },
    SET_USERVIEWPROFILE(state, userViewProfile) {
      state.userViewProfile = userViewProfile
    },
    SET_ADDUSERTAGADMIN(state, addUserTagAdmin) {
      state.addUserTagAdmin = addUserTagAdmin
    },
    SET_FILEURL(state, fileURL) {
      state.fileURL = fileURL
    },
    CLEAR_USERSETTINGS(state) {
      state.isUserProfileLoaded = false
      state.sys_users = []
      state.sys_roles = []

      // // new Object(state.userProfile)
      // state.userProfile = {}
      // delete state.userProfile.permissions
      // delete state.userProfile.roles
      // state.userProfile.update
      // state.userProfile = new Object()

      if (state.userProfile.permissions !== undefined) {
        state.userProfile.permissions.forEach(permission =>
        permission = {})
        delete state.userProfile.permissions
        delete state.userProfile.roles
      }
      delete state.userProfile.permissions
      delete state.userProfile.roles
      Object.assign(state.userProfile, {})
      state.userProfile = {}
      state.userProfile.update
    }
  },
}
