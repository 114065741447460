import Vue from 'vue'
import Vuex from 'vuex'

// Modules
// import authenStoreModule from '@/views/pages/authentication/authenStoreModule'
import app from './app'
import authen from './authen'
import product from './product'
import stripe from './stripe'
import site from './site'
import payment from './payment'
import coupon from './coupon'

import task from './app/task'
import comment from './app/comment'
import doc from './app/doc'
import channel from './app/channel'
import response from './app/response'
import hub from './app/hub'
import faq from './app/faq'
// import profile from './app/profile'
// import admin from './app/admin'
import history from './app-config/history'
import histAuth from './app-config/histAuth'
import appConfig from './app-config'

import config from './app-config/config'
import baseConfig from './app-config/baseConfig'
import verticalMenu from './vertical-menu'

import page from './page'
import blog from './page/blog'
import note from './page/note'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    authen,
    product,
    stripe,
    site,
    payment,
    coupon,
    task,
    comment,
    doc,
    channel,
    response,
    hub,
    faq,
    // profile,
    // admin,
    history,
    histAuth,
    appConfig,
    config,
    baseConfig,
    verticalMenu,
    // authenStoreModule,
    page,
    blog,
    note
  },
  strict: process.env.DEV,
})
