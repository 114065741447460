// eslint-disable-next-line import/no-cycle
import { db } from '@/main'
export default {
  namespaced: true,

  state: {
    payments: [],
  },

  actions: {
    async getPayments({ commit }) {
      let snapshot = await db.collection('payments')
      .get()
      let apayments = [];
      snapshot.forEach(doc => {
        let appData = doc.data()
        appData.id = doc.id
        apayments.push(appData)
      })
      commit('SET_PAYMENTS', apayments)
    },
  },

  getters: {},

  mutations: {
    SET_PAYMENTS(state, payload) {
      state.payments = payload
    },
  },

}
