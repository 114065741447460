import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '@/firebase' // added for FB's auth
import apps from './routes/apps'
import pages from './routes/pages'
import store from '@/store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', 
      redirect: { name: 'experience' },
      // props: (route) => ({ code: route.query.apiKey })
    },
    ...apps,
    ...pages,
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  let uri = window.location.search.substring(1)
  let params = new URLSearchParams(uri)

  store.dispatch('authen/setAuthMode', params.get("authMode"))
  store.dispatch('authen/setPromoCode', params.get("promoCode"))


  // console.log('param: ' + params.get("authMode"))
  // if (params.get("authMode")=='register') {
  //   store.dispatch('authen/setAuthMode', 'register')
  // }

  // Check if it is email link
  // store.dispatch('authen/signInWithEmailLink')

  // listen login action
  auth.onAuthStateChanged(function(user) {
    if (user) {
      // console.log('--2--LOGGED IN')
      // console.log('***user.id: ' + user.id)
      // console.log('***user.email: ' + user.email)
      if (user.emailVerified) { // allow login
        // console.log('--2--email verified')
        store.dispatch('authen/setVerifiedEmail', user.email)
        // if href && email, put user.email to 'verifiedEmail'
        // 'verifiedEmail' pre-saved to 'email' field
      } else {  // save error msg to store, being displayed in Login
        // console.log('--2--email NOT verified')
      }
    } else {  // routed to Login page?
      // console.log('--2--NOT LOGGED IN')
    }
  });
  
  // console.log('--2--auth.currentUser: ' + auth.currentUser)
  
  next()
})


// added for FB's auth
// navigation guard to check for logged in users
// router.beforeEach((to, from, next) => {
//   const requiresAuth = to.matched.some(x => x.meta.requiresAuth)  
//   const href = window.location.href
//   // const email = window.localStorage.getItem('emailForSignIn')
  
//   // Normal Login/Refresh
//   // console.log('---------------------')
//   // console.log('requiresAuth: ' + requiresAuth)
//   // console.log('auth.currentUser: ' + auth.currentUser)

//   if (requiresAuth && auth.currentUser==null) {
//     // console.log('Going LOGIN')
//     next('auth-login')
//   } else {
//     // console.log('Going NEXT')
//     // next()
//     if ( auth.isSignInWithEmailLink(href) ) {
//       // console.log('auth.isSignInWithEmailLink(href) YES')
//       let uri = window.location.search.substring(1); 
//       let params = new URLSearchParams(uri);
//       // console.log('query email: ' + params.get("email"));

//       if (params.get("email")) {
//         store.dispatch('authen/setIsSignInWithEmailLink', true)
//         store.dispatch('authen/setSigninEmail', params.get("email"))
//       }

//     } else {
//       // console.log('auth.isSignInWithEmailLink(href) NO')
//     }

//     next()

//     // auth.onAuthStateChanged(function(user) {
//     //   // console.log('user: ' + user)
//     //   if (user) {
//     //     if (user.emailVerified) { // allow login
//     //       // console.log('email verified')
//     //       user.sendEmailVerification()
//     //       store.dispatch('authen/setIsEmailVerified', true)
//     //       // this.isEmailVerified = true
//     //       next()
//     //     } else {  // save error msg to store, being displayed in Login
//     //       // console.log('email NOT verified')
//     //       store.dispatch('authen/setIsEmailVerified', false)
//     //       // this.isEmailVerified = false
//     //       // next('auth-login')
//     //       next()

//     //       // fixing, comment so far....
//     //       // store.dispatch('authen/logout', false)
//     //       // let errMsg = 'Please check your email account to verify your email.'
//     //       // store.dispatch('authen/setLoginErrorMsg', errMsg)
//     //       // window.location.reload()
//     //     }
//     //   } else {
//     //     next()
//     //   }
//     // });


//   }


//   // MOVED TO LOGIN.VUE
//   // const href = window.location.href
//   // const email = window.localStorage.getItem('emailForSignIn') // eliminate this next ----------------

//   // if ( auth.isSignInWithEmailLink(href) ) {
//   //   console.log('auth.isSignInWithEmailLink(href) is true') // to be deleted ------------
    
//   //   if (email) {  
//   //     store.dispatch('authen/setSigninEmail', email)
//   //   }
//   //   next()
//   // } else {
//   // }

//   // const IsItABackButton = window.popStateDetected
//   // window.popStateDetected = false
//   // if (IsItABackButton && from.meta.someLogica) {
//   //   console.log('back button is pressed')
//   //   // next(false) 
//   //   return ''
//   // }
// })

// For splash screen
// Remove afterEach hook if you are not using splash screen

router.afterEach((to) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
