// eslint-disable-next-line import/no-cycle
import { db } from '@/main'
export default {
  namespaced: true,

  state: {
    site: {},
    mSites: [],   // site record referred by marketers
    isSiteLoaded: false,
    isNewSiteCreated: false, // record if new site is created
  },

  actions: {
    async getSite({ commit }, payload) {
      let snapshot = await db.collection('sites')
      .where('cusId', '==', payload)
      .get()
      let sites = [];
      snapshot.forEach(doc => {
        let appData = doc.data()
        appData.id = doc.id
        sites.push(appData)
      })
      commit('SET_SITE', sites[0])
      commit('SET_ISSITELOADED', true)
    },
    
    async getMarketerSites({ commit }, payload) {
      let snapshot = await db.collection('sites')
      .where('promoCode', '==', payload)
      .get()
      let sites = [];
      snapshot.forEach(doc => {
        let appData = doc.data()
        appData.id = doc.id
        sites.push(appData)
      })
      commit('SET_MSITES', sites)
    },

    async addSite({ commit }, payload) {
      await db
        .collection('sites')
        .add({
          cusId: payload.cusId,
          regUser: payload.regUser,
          regUserEmail: payload.regUserEmail,
          since: payload.since,
          till: payload.till,
          till_storage: payload.till_storage,
          plan: payload.plan,
          numOfUsersLimit: payload.numOfUsersLimit,
          numOfUsersUsed: payload.numOfUsersUsed,
          storageLimit: payload.storageLimit,
          storageUsed: payload.storageUsed,
          sub_numTier: payload.sub_numTier,
          sub_numStorage: payload.sub_numStorage,
          sub_isResolved: payload.sub_isResolved,
          createdAt: payload.createdAt,
          updatedAt: payload.updatedAt,
          zPartnerCode: payload.zPartnerCode,
          promoCode: payload.promoCode
        })
        .then(function (docRef) {
          payload.id = docRef.id
        })
      commit('SET_SITE', payload)
      // commit('SET_ISSITELOADED', true)
      // commit('SET_NEWSITECREATED')
    },
    
    async updateSite({ commit }, payload) {
      await db
        .collection('sites')
        .doc(payload.id)
        .update({
          cusId: payload.cusId,
          regUser: payload.regUser,
          regUserEmail: payload.regUserEmail,
          since: payload.since,
          till: payload.till,
          till_storage: payload.till_storage,
          plan: payload.plan,
          numOfUsersLimit: payload.numOfUsersLimit,
          numOfUsersUsed: payload.numOfUsersUsed,
          storageLimit: payload.storageLimit,
          storageUsed: payload.storageUsed,
          sub_numTier: payload.sub_numTier,
          sub_numStorage: payload.sub_numStorage,
          sub_isResolved: payload.sub_isResolved,
          createdAt: payload.createdAt,
          updatedAt: payload.updatedAt,
          zPartnerCode: payload.zPartnerCode,
          promoCode: payload.promoCode
        })
      commit('SET_SITE', payload)
    },
  },

  getters: {},

  mutations: {
    SET_SITE(state, payload) {
      state.site = payload
    },
    SET_MSITES(state, payload) {
      state.mSites = payload
    },
    SET_ISSITELOADED(state, payload) {
      state.isSiteLoaded = payload
    },
    SET_NEWSITECREATED(state, payload) {
      state.isNewSiteCreated = payload
    },
  },

}
