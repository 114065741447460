export default [
  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: '/experience',
    name: 'experience',
    component: () => import('@/views/apps/Experience.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      requiresAuth: true
    },
    // for Navbar, clicking on task bring to task module
    children: [
      { path: ':selectedTaskId', 
        name: 'apps-todo-selectedTaskId',
        component: () => import('@/views/apps/todo/Todo.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'todo-application',
          navActiveLink: 'todo-application',
          requiresAuth: true,
        },
      }
    ]
  },
  {
    path: '/apps/todo/:module',
    name: 'apps-todo-task',
    component: () => import('@/views/apps/todo/Todo.vue'),
    props: true,
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo-module',
    },
  },
  {
    path: '/apps/todo/:id',
    name: 'apps-todo-id',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo-id',
    },
  },
  {
    path: '/apps/todo/:filter',
    name: 'apps-todo-filter',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/todo/tag/:tag',
    name: 'apps-todo-tag',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- COMMENT  ---------------------------------------*
  // *===============================================---*  
  {
    path: '/apps/comment',
    name: 'apps-comment',
    component: () => import('@/views/apps/comment/Comment.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
    children: [
      { path: ':selectedChannelId', 
        name: 'apps-comment-selectedChannelId',
        component: () => import('@/views/apps/comment/Comment.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'chat-application',
          navActiveLink: 'chat-application',
        },
      }
    ]
  },
  // *===============================================---*
  // *--------- DOCUMENT ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/doc',
    name: 'apps-doc',
    component: () => import('@/views/apps/doc/Doc.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
    children: [
      { path: ':selectedDocId', 
        name: 'apps-doc-selectedDocId',
        component: () => import('@/views/apps/doc/Doc.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'chat-application',
          navActiveLink: 'chat-application',
        },
      }
    ]
  },
  // *===============================================---*
  // *--------- HUB ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/hub-team',
    name: 'apps-hub-team',
    meta: {
      pageTitle: 'Team',
    },
    component: () => import('@/views/apps/hub/Hub.vue'),
    props: true,
  },
  // *===============================================---*
  // *--------- Directory---------------------------------*
  // *===============================================---*
  {
    path: '/apps/users/list',
    name: 'apps-hub-directory',
    component: () => import('@/views/apps/hub/HubDirectory.vue'),
      meta: {
        pageTitle: 'Directory'
      },
  },
  // *===============================================---*
  // *--------- SETTINGS ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/setting',
    name: 'apps-setting',
    component: () => import('@/views/apps/setting/Setting.vue'),
    meta: {
      // contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      pageTitle: 'Settings',
    },
  },
  // *===============================================---*
  // *--------- PROFILE --------------------------------*
  // *===============================================---*
  {
    path: '/apps/profile',
    name: 'apps-profile',
    component: () => import('@/views/apps/profile/Profile.vue'),
    meta: {
      // contentRenderer: 'sidebar-left',
      contentClass: 'profile-application',
      pageTitle: 'Profile',
    },
  },
  // *===============================================---*
  // *--------- ADMIN --------------------------------*
  // *===============================================---*
  {
    path: '/apps/userAdmin',
    name: 'apps-userAdmin',
    component: () => import('@/views/apps/admin/UserAdmin.vue'),
    meta: {
      pageTitle: 'Users',
    },
  },
  {
    path: '/apps/userRole',
    name: 'apps-userRole',
    component: () => import('@/views/apps/admin/UserRole.vue'),
    meta: {
      pageTitle: 'User Roles',
    },
  },
  {
    path: '/apps/userPermission',
    name: 'apps-userPermission',
    component: () => import('@/views/apps/admin/UserPermission.vue'),
    meta: {
      pageTitle: 'User Permissions',
    },
  },
  {
    path: '/apps/faq',
    name: 'apps-faq',
    component: () => import('@/views/apps/FAQ.vue'),
    meta: {
      pageTitle: 'FAQ',
    },
  },
  // *===============================================---*
  // *--------- ROLE --------------------------------*
  // *===============================================---*
  // {
  //   path: '/apps/userRole',
  //   name: 'apps-userRole',
  //   component: () => import('@/views/apps/admin/UserRole.vue'),
  // },
]
