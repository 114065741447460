// eslint-disable-next-line import/no-cycle
import { db } from '@/main'
export default {
  namespaced: true,

  state: {
    stripe_products: [],
    stripe_customer: {},
    // isLoading: false,
    lastCheckOut: {},
    lastPayment: {},
    payment: {},
    allSubscriptions: [],
    lastSubscription: {},
    // if one payment finished, force user to pay in next-login
    isPaymentFinished: false,
  },

  actions: {    
    // 'products' is stripe products
    // 'productss' is goalpen products (in product.js)
    async getStripeProducts({ commit }) {
      let productsQuerySnap = await db.collection('products').get()

      let aproducts = [];
      productsQuerySnap.forEach(async (doc) => {
        let pricesQuerySnap = await db.collection('products').doc(doc.id).collection('prices').get()

        aproducts.push({
          id: doc.id,
          ...doc.data(),
          prices: pricesQuerySnap.docs.map((price) => {
            return {
              id: price.id,
              ...price.data(),
            };
          }),
        });
      });
      commit('SET_STRIPE_PRODUCTS', aproducts)
    },
    // async setIsLoading( { commit }, payload ) {
    //   commit('SET_ISLOADING', payload)
    // },
    async addStripeCustomer({ rootState }, payload) {
      console.log('rootState.authen.userProfile.promoCode: ' + rootState.authen.userProfile.promoCode)
      await db
        .collection('customers')
        .doc(rootState.authen.userProfile.id)
        .collection('checkout_sessions')
        .add({
          price: payload,
          success_url: 'http://info.goalpen.com/success.html',
          cancel_url: 'http://info.goalpen.com/cancel.html',          
          allow_promotion_codes: true, // To enforce programmatically set discount
          discounts: {
            promotion_code: "rootState.authen.userProfile.promoCode" // eg. 'promo_0123456789'
          },
        })
    },
    async checkStripeCustomer({ rootState, commit, dispatch }) {
      await db
        .collection('customers')
        .doc(rootState.authen.userProfile.id)
        .onSnapshot((doc) => {
          commit('SET_STRIPE_CUSTOMER', doc.data())

          // get checkout data
          if ( doc.data() !== undefined ) {
            dispatch('getCheckOutSession')
          }
      });
    },
    async getCheckOutSession({ rootState, commit, dispatch }) {
      let snapshot = await db
        .collection('customers')
        .doc(rootState.authen.userProfile.id)
        .collection('checkout_sessions')
        .get()
      let checkout = []
      snapshot.forEach(doc => {
        let appData = doc.data()
        appData.id = doc.id
        checkout.push(appData)
      })
      commit('SET_CHECKOUT_SESSION', checkout)
      dispatch('checkCheckOutSession')
    },
    async checkCheckOutSession({ rootState, commit}) {
      await db
        .collection('customers')
        .doc(rootState.authen.userProfile.id)
        .collection('checkout_sessions')
        .onSnapshot((snap) => {
          let checkout = []
          let lastCheckOut = {}
          snap.forEach(doc => {
            let appData = doc.data()
            appData.id = doc.id
            checkout.push(appData)
          })
          lastCheckOut = checkout.sort((a, b) => a.created > b.created ? -1 : 1) [0]
          commit('SET_CHECKOUT_SESSION', lastCheckOut)
        })
    },
    // check if payment has been finished
    async checkPayment({ rootState, commit}) {
      await db
        .collection('customers')
        .doc(rootState.authen.userProfile.id)
        .collection('payments')
        .onSnapshot((snap) => {
          let payment = []
          let lastPayment = {}
          snap.forEach(doc => {
            let appData = doc.data()
            appData.id = doc.id
            payment.push(appData)
          })
          lastPayment = payment.sort((a, b) => a.created > b.created ? -1 : 1) [0]
          commit('SET_PAYMENT_SESSION', lastPayment)
        })
    },
    // check if payment has been finished
    async checkSubscription({ rootState, commit}) {
      await db
        .collection('customers')
        .doc(rootState.authen.userProfile.id)
        .collection('subscriptions')
        .onSnapshot((snap) => {
          let subscriptions = []
          let lastSubscription = {}
          snap.forEach(doc => {
            let appData = doc.data()
            appData.id = doc.id
            subscriptions.push(appData)
          })
          lastSubscription = subscriptions.sort((a, b) => a.created > b.created ? -1 : 1) [0]
          commit('SET_ALLSUBSCRIPTIONS', subscriptions)
          commit('SET_LASTSUBSCRIPTION', lastSubscription)
        })
      // commit('SET_ISLOADING', false)
    },
    // add payment records
    // could be present in admin module only
    async addPayment({ commit }, payload) {
      await db
        .collection('payments')
        .add({
          cusId: payload.cusId,
          cusPayerId: payload.cusPayerId,
          payAt: payload.payAt,
          product_name: payload.product_name,

          productType: payload.productType,          
          billingPeriod: payload.billingPeriod,
          description: payload.description,
          numUsers: payload.numUsers,
          price: payload.price,
          storage: payload.storage,

          subscriptionFrom: payload.subscriptionFrom,
          subscriptionTo: payload.subscriptionTo,

          status: payload.status,

          stripe_id_customer: payload.stripe_id_customer,
          stripe_id_checkout: payload.stripe_id_checkout,
          stripe_id_payment: payload.stripe_id_payment,
          stripe_id_subscription: payload.stripe_id_subscription,
          stripe_payment_created: payload.stripe_payment_created,
          stripe_payment_amount_currency: payload.stripe_payment_amount_currency,
          stripe_payment_amount_received: payload.stripe_payment_amount_received,
          stripe_subscription_current_period_start: payload.stripe_subscription_current_period_start,
          stripe_subscription_current_period_end: payload.stripe_subscription_current_period_end,
          
          stripe_customer_email: payload.stripe_customer_email,
          stripe_link_customer: payload.stripe_link_customer,
          stripe_link_subscription: payload.stripe_link_subscription,
        })
        .then(function (docRef) {
          payload.id = docRef.id
        })
      commit('SET_PAYMENT', payload)
    },
    setPaymentFinished({ commit }, payload) {
      commit('SET_PAYMENTFINISHED', payload)
    }
  },

  getters: {},

  mutations: {
    // SET_ISLOADING(state, payload) {
    //   state.isLoading = payload
    // },
    SET_STRIPE_PRODUCTS(state, payload) {
      state.stripe_products = payload
    },
    SET_STRIPE_CUSTOMER(state, payload) {
      state.stripe_customer = payload
    },
    SET_CHECKOUT_SESSION(state, payload) {
      state.lastCheckOut = payload
    },
    SET_PAYMENT_SESSION(state, payload) {
      state.lastPayment = payload
    },
    SET_PAYMENT(state, payload) {
      state.payment = payload
    },
    SET_ALLSUBSCRIPTIONS(state, payload) {
      state.allSubscriptions = payload
    },
    SET_LASTSUBSCRIPTION(state, payload) {
      state.lastSubscription = payload
    },
    SET_PAYMENTFINISHED(state, payload) {
      state.isPaymentFinished = payload
    },
  },

}
