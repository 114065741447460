// eslint-disable-next-line import/no-cycle
import { db } from '@/main'

const state = () => ({

  // config 2.0:
  baseConfigArray: []
})

const actions = {
  setBaseConfigArray({ commit }, payload) {
    commit('SET_BASE_CONFIG_ARRAY', payload)
  },
  async getBaseConfigArray({ dispatch, commit }) {
    const snapshot = await db.collection("sys_baseConfig")
      .get();
    let baseConfigArray = [];
    snapshot.forEach(doc => {
      let appData = doc.data()
      appData.id = doc.id
      baseConfigArray.push(appData)
      // baseConfigArray = appData
    })
    commit('SET_BASE_CONFIG_ARRAY', baseConfigArray)
  },
}

const mutations = {
  SET_BASE_CONFIG_ARRAY(state, payload) {
      state.baseConfigArray = payload
  },
}

// getters
const getters = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}