import { db } from '@/main'
import { format } from 'date-fns'

export default {
  namespaced: true,

  state: {
    // channel: [],  // channel is the selected tasks (or empty task) for blogs
    blogs: [],
    blog: {},
    blogView: 'list',
    addBlogTag: false,
    blogTags: [],
    isTeamBlog: true,
    parentTaskId: ''
  },

  getters: {
    // fetchBlogsByParentId: (state) => (id) => {
    //     return state.blogs.filter(blog => blog.zParentId === id)
    // },

    // fetchBlogsById: (state) => (id) => {
    //     return state.blogs.filter(blog => blog.id === id)
    // },
  },

  actions: {
    async getBlogs({ commit, dispatch }, payload) {
        let snapshot = await db.collection("blogs")
          .where('zCusId', '==', payload)
          .get();
        let blogs = [];
        snapshot.forEach(doc => {
            let appData = doc.data()
            appData.id = doc.id
            blogs.push(appData)
        })
        commit('SET_BLOGS', blogs)
        dispatch('orderTagsFromBlogs', blogs)
        // console.log('blogs loaded')
    },
    async getDeltaBlogs({ commit, rootState }, payload) {
        let snapshot = await db.collection("blogs")
          .where('zCusId', '==', payload)
          .where('updatedAt', '>=', rootState.authen.loginTime + '\uf8ff')
          .limit(50)
          .get();
        let deltaBlogs = [];
        snapshot.forEach(doc => {
            let appData = doc.data()
            appData.id = doc.id
            deltaBlogs.push(appData)
        })
        commit('ADD_DELTABLOGS', deltaBlogs)

        deltaBlogs.forEach(deltaBlog => {
          commit('ADD_BLOGTAGS', deltaBlog.tags)
        })
    },
    async addBlog({ commit, dispatch }, payload) {
        await db
            .collection('blogs')
            .add({
                blogTitle: payload.blogTitle,
                excerpt: payload.excerpt,
                content: payload.content,
                blogPhotoURL: payload.blogPhotoURL,
                owner: payload.owner,
                status: payload.status,
                bookmark: payload.bookmark,
                tags: payload.tags,
                zCusId: payload.zCusId,
                zParentId: payload.zParentId,
                zParentType: payload.zParentType, //'task' or 'goal'
                createdAt: payload.createdAt,
                updatedAt: payload.updatedAt
            })
            .then(function (docRef) {
                payload.id = docRef.id
            })          
        commit('SET_BLOG', payload)
        commit('ADD_TOBLOGS', payload)
        commit('ADD_BLOGTAGS', payload.tags)
  
        payload.instr = 'Add'
        dispatch('packageHistoryItem', payload)
    },
    async updateBlog({ commit, dispatch, state }, payload) {
        await db
            .collection('blogs')
            .doc(payload.id)
            .update({
                blogTitle: payload.blogTitle,
                excerpt: payload.excerpt,
                content: payload.content,
                blogPhotoURL: payload.blogPhotoURL,
                owner: payload.owner,
                status: payload.status,
                bookmark: payload.bookmark,
                tags: payload.tags,
                zCusId: payload.zCusId,
                zParentId: payload.zParentId,
                zParentType: payload.zParentType, //'task' or 'goal'
                createdAt: payload.createdAt,
                updatedAt: payload.updatedAt
            })
        commit('SET_BLOG', payload)
        dispatch('orderTagsFromBlogs', state.blogs)
        
        payload.instr = 'Update'
        dispatch('packageHistoryItem', payload)
    },
    async deleteBlog({ state, dispatch }, payload) {
      // delete history item first
      payload.instr = 'Delete'
      dispatch('packageHistoryItem', payload)

      let payloadIndex = -1
      await db.collection("blogs").doc(payload.id).delete().then(() => {
          payloadIndex = state.blogs.indexOf(payload)
          if (payloadIndex > -1) {
            state.blogs.splice( payloadIndex, 1 )
          }
      }).catch((error) => {
          console.error("Error removing blog: ", error);
      });
      
      dispatch('orderTagsFromBlogs', state.blogs)
    },
    orderTagsFromBlogs({ commit }, payload) {
      let blogTagsArray = []
      let uniqueArray = []
  
      // 1. Concat tags from all blogs.tags into blogTagsArray
      payload
        .forEach(blog => blogTagsArray = blogTagsArray.concat(blog.tags))
  
      // 2. Dudup blogTagsArray
      blogTagsArray.forEach(tag => {
        if ( uniqueArray.indexOf(tag)==-1 && tag.trim()!=='' ) {
          uniqueArray.push(tag)
        }
      })
  
      commit('SET_BLOGTAGS', uniqueArray)
    },
    async packageHistoryItem({ dispatch, rootState }, payload) {
  
      // 1. prepare historyItem fields (other than 'action')
      let historyItem = {}
      let fuAction = ''
      
      historyItem.module = 'Blog'
      historyItem.moduleId = payload.id
      historyItem.moduleParentId = payload.zParentId
      historyItem.moduleParentType = payload.zParentType
      historyItem.at = format(new Date(payload.updatedAt), "yyyy-MM-dd HH:mm")
      historyItem.by = payload.owner
      historyItem.usersNotified = []
      historyItem.zCusId = payload.zCusId
  
      // 2. prepare 'action' field acc. to payload.instr': 
      //  'Add' / 'Update' / 'Remove' history record        (task / comment / doc / blog / note )
      //  'Like' / 'Heart' / 'Happy' / 'Sad' history record (comment)
      // * only task and comment in notifications           (i.e. 'at' and 'by' fields)
      
      switch ( payload.instr ) {
        case 'Add':
          historyItem.action = 'Add'
          dispatch('history/addHistoryItem', historyItem, { root: true })
          break
        case 'Update':
          historyItem.action = 'Update'
          fuAction = 'Update'
          break
        case 'Delete':
          historyItem.action = 'Delete'
          fuAction = 'Delete'
      }
  
      // Proceed if 'Update' or 'Remove' or 'Delete'
      if ( fuAction !== '' ) {
        // Dig out the exiting historyItem from store, grab the id before being updated
        let mappedHistItem = rootState.history.historyItems.find(hItem => hItem.moduleId===payload.id)
        if ( mappedHistItem !== undefined ) {
          historyItem.id = mappedHistItem.id
          if ( fuAction == 'Update' ) {
            dispatch('history/updateHistoryItem', historyItem, { root: true })
          }
          if ( fuAction == 'Delete' ) {
            dispatch('history/deleteHistoryItem', historyItem, { root: true })
          }
        }
      }
  
      // remove 'instr' field from payload
      delete payload.instr
      delete payload.usersNotified
      fuAction = ''
    },
    setBlog({ commit }, payload) {
        commit('SET_BLOG', payload)
    },
    setAddBlogTag({ commit }, payload) {
      commit('SET_ADDBLOGTAG', payload)
    },
    setBlogView({ commit }, payload) {
      commit('SET_BLOGVIEW', payload)
    },
    setIsTeamBlog({ commit }, payload) {
      commit('SET_ISTEAMBLOG', payload)
    },
    clearBlogs({ commit }) {
      commit('CLEAR_BLOGS')
    },
    setParentTaskId({ commit }, payload) {
      commit('SET_PARENTTASKID', payload)
    },
  },

  mutations: {
    // SET_UPDATELIST(state, updateList) {
    //     state.updateList = updateList
    // },
    // SET_LISTUPDATED(state, listUpdated) {
    //     state.listUpdated = listUpdated
    // },
    SET_BLOG(state, payload) {
        state.blog = payload
        // 2. Update 'blog' into the existing "blogs" array
        state.blogs.forEach(eachBlog => {
          if (eachBlog.id === payload.id) {
            eachBlog.blogTitle = payload.blogTitle,
            eachBlog.excerpt = payload.excerpt,
            eachBlog.content = payload.content,
            eachBlog.blogPhotoURL = payload.blogPhotoURL,
            eachBlog.owner = payload.owner,
            eachBlog.status = payload.status,
            eachBlog.bookmark = payload.bookmark,
            eachBlog.tags = payload.tags,
            eachBlog.zCusId = payload.zCusId,
            eachBlog.zParentId = payload.zParentId,
            eachBlog.zParentType = payload.zParentType,
            eachBlog.createdAt = payload.createdAt,
            eachBlog.updatedAt = payload.updatedAt
          }
        })
    },
    SET_BLOGS(state, payload) {
        state.blogs = payload
    },
    ADD_TOBLOGS(state, payload) {
      let isBlogFound = false
      state.blogs.forEach(blog => {
        if (blog.id === payload.id) {
          isBlogFound = true
        }
      })
      if (!isBlogFound) {
        state.blogs.push(payload)
      }
    },
    ADD_DELTABLOGS(state, payload) {
      let isFound = false
        payload.forEach(deltaBlog => {
          state.blogs.forEach(blog => {
            if (blog.id === deltaBlog.id) {
              isFound = true
            }
          })
          if (!isFound) {
            state.blogs.push(deltaBlog)
          } else {
            // Still have to update the blog!
            let updatedBlogIndex = state.blogs.findIndex(blog => blog.id == deltaBlog.id)
            state.blogs[updatedBlogIndex] = deltaBlog
            state.blogs.update
            isFound = false
          }
        })
        // state.blogs.update
    },
    REMOVE_BLOGS(state, payload) {
        state.blogs.pop(payload)
        state.blogs.update
        // state.blogs = state.blogs.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1)
    },
    SET_ADDBLOGTAG(state, payload) {
      state.addBlogTag = payload
    },
    SET_BLOGVIEW(state, payload) {
      state.blogView = payload
    },
    SET_BLOGTAGS(state, tags) {
      state.blogTags = tags
    },
    ADD_BLOGTAGS(state, payload) {
      payload.forEach(newTag => {
        let newTagIndex = state.blogTags.indexOf(newTag)
        if ( newTagIndex==-1 && newTag.trim()!=='' ) {
          state.blogTags.push(newTag)
        }
      })
      // state.blogTags.push(
      //   blogTags.find(x => !state.blogTags.includes(x))
      // )
      // state.blogTags.update
      // state.blogTags = state.blogTags.filter(entry => entry.trim() !== '' && entry!==undefined)
    },
    SET_ISTEAMBLOG(state, payload) {
      state.isTeamBlog = payload
    },
    CLEAR_BLOGS(state) {
      state.blog = {}
      state.blogs = []
      state.blogTags = []
      state.blogView = ''
    },
    SET_PARENTTASKID(state, payload) {
      state.parentTaskId = payload
    },
  },
}
