// eslint-disable-next-line import/no-cycle
import { db } from '@/main'

const state = () => ({
  // VerticalNavMenu - Loading menu
  isMenuLoaded: false,

  // config 2.0:
  roleConfigAdded: false,
  configItems2: [],
  statusItems: [],  //done
  blogStatusItems: [],  //done
  blogImages: [], //done
  taskTypes: [],
  goalTypes: [],
  fileTypes: [],
  fileSizeLimit: '',
  settingTab: 'setting',
  usageTab: 'usage',
  taskCustomFields: [],
  cfIndex: 99,    // 0-9

  // Trigger Tour on Navbar
  nbTourModal: false,
  nbTourModule: 0,
  // 0: Tasks
  // 1: Comments
  // 2: Documents
  // 3: Notes
  // 4: Hub...

  // Custom Field Settings:
  fieldType: ['text', 'email', 'list', 'computed'],
  position: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // row, column and field
  previewRole: '', // One of sys-role in form of Id, used in Selecting Role in Preview page

  // in 'Goals.vue', when creating new goal, tell the system the Category.
  currentGoalCategory: '',

  // Array of task ID telling the UI to display the sub-tasks
  panelSubtasks: [],
  configItems: [],
  configItem: {},
  coupon: [],
})

const actions = {
  setCfIndex({ commit }, payload) {
    commit('SET_CF_INDEX', payload)
  },
  setSettingTab({ commit }, payload) {
    commit('SET_SETTING_TAB', payload)
  }, 
  setUsageTab({ commit }, payload) {
    commit('SET_USAGE_TAB', payload)
  }, 
  // ------------ BELOW NOT USEFUL ----------
  // setThemeColor({ commit }, payload) {
  //   commit('SET_THEME_COLOR', payload)
  // },
  // setCurrentGoalCategory({ commit }, payload) {
  //   commit('SET_CURRENTGOALCATEGORY', payload)
  // },
  // toggleDrawer({ commit }, payload) {
  //   commit('SET_DRAWER', !payload)
  // },
  // setDrawer({ commit }, payload) {
  //   commit('SET_DRAWER', payload)
  // },
  // toggleAppBar({ commit }, payload) {
  //   commit('SET_APPBAR', !payload)
  // },
  // setAppBar({ commit }, payload) {
  //   commit('SET_APPBAR', payload)
  // },
  // toggleExpandOnHover({ commit }, payload) {
  //   commit('SET_EXPANDONHOVER', !payload)
  // },
  // toggleRmini({ commit }, payload) {
  //   commit('SET_RMINI', !payload)
  // },
  // setRmini({ commit }, payload) {
  //   commit('SET_RMINI', payload)
  // },
  // setRminiWidth({ commit }, payload) {
  //   commit('SET_RMINIWIDTH', payload)
  // },
  // togglePanel({ commit }, payload) {
  //   commit('SET_PANEL', !payload)
  // },
  // setIsMobile({ commit }, payload) {
  //   commit('SET_ISMOBILE', payload)
  // },
  // setPreviewRole({ commit }, payload) {
  //   commit('SET_PREVIEWROLE', payload)
  // },
  // addPanelSubtasks({ commit }, payload) {
  //   commit('ADD_PANELSUBTASKS', payload)
  // },
  // removePanelSubtasks({ commit }, payload) {
  //   commit('REMOVE_PANELSUBTASKS', payload)
  // },
  // setPanelType({ commit }, payload) {
  //   commit('SET_PANELTYPE', payload)
  // },
  // setGoalPanelMode({ commit }, payload) {
  //   commit('SET_GOALPANELMODE', payload)
  // },
  // setPanelDblClickForEdit({ commit }, payload) {
  //   commit('SET_PANELDBLCLICKFOREDIT', payload)
  // },
  // async getConfigItems({ commit }) {
  //   const snapshot = await db.collection("sys_config").get();
  //   let configItems = [];
  //   snapshot.forEach(doc => {
  //     let appData = doc.data()
  //     appData.id = doc.id
  //     configItems.push(appData)
  //   })
  //     commit('SET_CONFIGITEMS', configItems)
  // },
  async getConfigItems2({ dispatch, commit }, payload) {
    const snapshot = await db.collection("sys_config2")
      .where('zCusId', '==', payload)
      .get();
    let configItems2 = [];
    snapshot.forEach(doc => {
      let appData = doc.data()
      appData.id = doc.id
      // configItems2.push(appData)
      configItems2 = appData
    })
    if (configItems2===undefined || configItems2.length==0) {
      // if empty, i.e. 1st-user => load the base
      const snapshotBase = await db.collection("sys_config2")
        .where('zCusId', '==', payload)
        .get();
      let configItems2Base = [];
      snapshotBase.forEach(doc => {
        let appDataBase = doc.data()
        appDataBase.id = doc.id
        // configItems2Base.push(appData)
        configItems2Base = appDataBase
      })
      configItems2Base.zCusId = payload
      commit('SET_CONFIGITEMS2', configItems2Base)
      dispatch('addConfigItem', configItems2Base)
    } else {
      commit('SET_CONFIGITEMS2', configItems2)
    }
  },
  async getCoupons({ commit }, payload) {
    const snapshot = await db.collection("coupons")
      .where('promoCode', '==', payload)
      .get();
    let couponItems = [];
    snapshot.forEach(doc => {
      let appData = doc.data()
      appData.id = doc.id
      couponItems = appData
    })
    commit('SET_COUPONS', couponItems)
  },
  async updateConfigItem2({ commit }, payload) {
    await db
      .collection('sys_config2')
      .doc(payload.id)
      .update({
        config: payload.config,
        customFields: payload.customFields,
        zCusId: payload.zCusId,
      })
    commit('SET_CONFIGITEMS2', payload)
  },
  async addConfigItem({ commit }, payload) {
    await db
      .collection('sys_config2')
      .add({
        config: payload.config,
        customFields: payload.customFields,
        zCusId: payload.zCusId
      })
      .then(function (docRef) {
        payload.id = docRef.id
      })
      commit('SET_CONFIGITEMS2', payload)
      commit('SET_ROLECONFIGADDED', true)
      // console.log('..... vuex addConfigItem DONE')
      // commit('ADD_CONFIGITEM', payload)
  },
  async updateConfigItem({ commit }, payload) {
    await db
      .collection('sys_config')
      .doc(payload.id)
      .update({
        name: payload.name,
        icon: payload.icon,
        color: payload.color,
        roles: payload.roles,   // roles accessible to this config
        _config: payload._config,
        order: payload.order,
      })
    commit('SET_CONFIGITEM', payload)
  },
  // Update Custom Fields (in Goal)
  async updateCustomGoalFields({ commit }, payload) {
    payload.forEach(payloadd => {
      db
        .collection('sys_config')
        .doc(payloadd.id)
        .update({                
          // _config: payload._config,
          // _id: payload._id,
          booEnabled: payloadd.booEnabled,
          booRequired: payloadd.booRequired,
          caption: payloadd.caption,
          fieldType: payloadd.fieldType,
          goalCategory: payloadd.goalCategory,
          name: payloadd.name,
          positionNewRow: payloadd.positionNewRow,
          positionWidth: payloadd.positionWidth,
          positionSpace: payloadd.positionSpace,
          positionRightMost: payloadd.positionRightMost,
          roles: payloadd.roles,
          validate1: payloadd.validate1,
          validate2: payloadd.validate2,
        })
    })    
    commit('UPDATE_CUSTOMFIELDS', payload)    
  },
  // Update Custom Fields (in Task)
  async updateCustomFields({ commit }, payload) {
      payload.forEach(payloadd => {
          db
              .collection('sys_config')
              .doc(payloadd.id)
              .update({                
                  // _config: payload._config,
                  // _id: payload._id,
                  booEnabled: payloadd.booEnabled,
                  booRequired: payloadd.booRequired,
                  caption: payloadd.caption,
                  fieldType: payloadd.fieldType,
                  name: payloadd.name,
                  positionNewRow: payloadd.positionNewRow,
                  positionWidth: payloadd.positionWidth,
                  positionSpace: payloadd.positionSpace,
                  positionRightMost: payloadd.positionRightMost,
                  roles: payloadd.roles,
                  validate1: payloadd.validate1,
                  validate2: payloadd.validate2,
              })
      })    
      commit('UPDATE_CUSTOMFIELDS', payload)    
  },
  // DANGEROUS??!!
  // async deleteConfigItem({ commit }, payload) {
  //     await db
  //         .collection('sys_config')
  //         .doc(payload)
  //         .delete();
  //     commit('SET_CONFIGITEM', null)
  //     commit('REMOVE_CONFIGITEM', payload)
  // },
  // only be called during logout
  clearConfigItems({ commit }) {
    commit('CLEAR_CONFIGITEMS')
  },
  setNbTourModal({ commit }, payload) {
    commit('SET_NB_TOUR_MODAL', payload)
  },
  setNbTourModule({ commit }, payload) {
    commit('SET_NB_TOUR_MODULE', payload)
  },
}

const mutations = {
  SET_NB_TOUR_MODAL(state, payload) {
    state.nbTourModal = payload
  },
  SET_NB_TOUR_MODULE(state, payload) {
    state.nbTourModule = payload
  },
    SET_ISMENULOADED(state, payload) {
      state.isMenuLoaded = payload
    },
    CLEAR_CONFIGITEMS(state) {
      state.configItems2 = []
      state.statusItems = []
      state.blogStatusItems = []
      state.blogImages = []
      state.taskTypes = []
      state.goalTypes = []
      state.fileTypes = []
      state.fileSizeLimit = ''
      state.settingTab = ''
      state.cfIndex = ''
      state.colorList = []
      state.fieldType = []
      state.position = []
      state.taskCustomFields = []
      state.fieldType = []
      
      state.panelSubtasks = []
    },
    SET_CONFIGITEMS2(state, payload) {
      state.configItems2 = payload
      // Level 1: Extract configInfo
      let configInfo = state.configItems2
      // let configInfo = state.configItems2.find(configItem2 => configItem2.name=='configInfo')
      
      // Level 2: 
      // Break down the objects and turn them into arrays or objects
      state.statusItems = Object.values(configInfo.config['Status'])
      state.blogStatusItems = Object.values(configInfo.config['BlogStatus'])
      state.blogImages = Object.values(configInfo.config['BlogImage'])
      state.taskTypes = Object.values(configInfo.config['TaskType'])
      state.goalTypes = Object.values(configInfo.config['GoalType'])
      state.fileTypes = Object.values(configInfo.config['FileType'])
      state.fileSizeLimit = configInfo.config['FileSizeLimit']
      state.taskCustomFields = Object.values(configInfo.customFields)
    },
    SET_COUPONS(state, payload) {
      state.coupon = payload
    },
    // SET_CONFIGITEM(state, payload) {
    //     state.configItem = payload
    // },
    // ADD_CONFIGITEM(state, payload) {
    //     state.configItems.push(payload)
    // },
    // REMOVE_CONFIGITEM(state, payload) {
    //     state.configItems = 
    //         state.configItems.filter(configItem => 
    //             configItem.id !== payload
    //         )
    // },
    // //Update ConfigItem - Custom Fields
    // //Called by updateCustomFields, payload as goal or task customFields x 10
    // //Update and SET configItems
    // UPDATE_CUSTOMFIELDS(state, payload) {
    //     // state.configItems = state.configItems.map(configItem => {
    //     //     let insertedItem = payload.find(customField => customField._config === configItem._config);
    //     //     return insertedItem ? { ...configItem, ...insertedItem } : configItem;
    //     // })
    //     state.configItems.forEach(configItem => {
    //         payload.forEach(payloadd => {
    //             if (configItem.id === payloadd.id) {
    //                 configItem === payloadd
    //             }
    //         })
    //     })
    // },
    SET_THEME_COLOR(state, payload) {
        state.themeColor = payload
    },
    SET_SETTING_TAB(state, payload) {
      state.settingTab = payload
    },
    SET_USAGE_TAB(state, payload) {
      state.usageTab = payload
    },
    SET_CF_INDEX(state, payload) {
      state.cfIndex = payload
    },
    SET_CURRENTGOALCATEGORY(state, payload) {
        state.currentGoalCategory = payload
    },
    SET_ROLECONFIGADDED(state, payload) {
      state.roleConfigAdded = payload
    },
    // SET_DRAWER(state, payload) {
    //     state.drawer = payload
    // },
    // SET_APPBAR(state, payload) {
    //     state.appBar = payload
    // },
    // SET_EXPANDONHOVER(state, payload) {
    //     state.expandOnHover = payload
    // },
    // SET_RMINI(state, payload) {
    //     state.rMini = payload
    // },
    // SET_RMINIWIDTH(state, payload) {
    //     state.rMiniWidth = payload
    // },
    // SET_PANEL(state, payload) {
    //     state.panel = payload
    // },
    // SET_ISMOBILE(state, isMobile) {
    //     state.isMobile = isMobile
    // }, 
    // SET_PREVIEWROLE(state, previewRole) {
    //     state.previewRole = previewRole
    // }, 
    // ADD_PANELSUBTASKS(state, payload) {
    //     if (state.panelSubtasks.indexOf(payload) === -1) {
    //         state.panelSubtasks.push(payload)
    //     }
    // },
    // REMOVE_PANELSUBTASKS(state, payload) {
    //     state.panelSubtasks.forEach(panelViewSubtask => {
    //         if (panelViewSubtask === payload) {
    //             state.panelSubtasks.splice(
    //                 state.panelSubtasks.indexOf(panelViewSubtask),
    //                 1
    //             )
    //         }
    //     })
    // },

    // SET_PANELTYPE(state, panelType) {
    //     state.panelType = panelType
    // },
    // SET_GOALPANELMODE(state, goalPanelMode) {
    //     state.goalPanelMode = goalPanelMode
    // },
    // SET_PANELDBLCLICKFOREDIT(state, panelDblClickForEdit) {
    //     state.panelDblClickForEdit = panelDblClickForEdit
    // },
    // SET_CONFIGITEMS(state, payload) {
    //   state.configItems = payload
    // },
}

// getters
const getters = {
  //   // defaultGoalPriorityItem(state) {
  //   //   let defaultGoalPriorityItem = {}
  //   //   defaultGoalPriorityItem = state.configItems.find(configItem =>
  //   //     // eslint-disable-next-line
  //   //     configItem._config === 'Goal Priority' && configItem.default === true)
  //   //   return defaultGoalPriorityItem
  //   // },
  //   // defaultTaskType(state) {
  //   //   let defaultTaskType = []
  //   //   defaultTaskType = state.configItems.find(configItem =>
  //   //     // eslint-disable-next-line
  //   //     configItem._config === 'Task Type' &&
  //   //     configItem.default === true)
  //   //   return defaultTaskType
  //   // },
  //   // defaultStatusItem(state) {
  //   //   let defaultStatusItem = []
  //   //   defaultStatusItem = state.configItems.find(configItem =>
  //   //     // eslint-disable-next-line
  //   //     configItem._config === 'Status' &&
  //   //     configItem.default === true)
  //   //   return defaultStatusItem
  //   // },
  //   // // config Custom Field in Goal
  //   // customGoalFields(payload) {
  //   //   return payload.configItems.filter(
  //   //     // eslint-disable-next-line no-underscore-dangle
  //   //     configItem => configItem._config === 'Custom Goal Fields',
  //   //   // eslint-disable-next-line
  //   //   ).sort((a, b) => a._id > b._id ? 1 : -1)
  //   // },
  //   // // config Custom Field in Task
  //   // customTaskFields(payload) {
  //   //   return payload.configItems.filter(configItem =>
  //   //     // eslint-disable-next-line
  //   //     configItem._config === 'Custom Task Fields',
  //   //   // eslint-disable-next-line
  //   //   ).sort((a, b) => a._id > b._id ? 1 : -1)
  //   // },
  //   // goalCategoryItems(payload) {
  //   //   return payload.configItems.filter(configItem =>
  //   //     // eslint-disable-next-line
  //   //     configItem._config === 'Goal Category',
  //   //   // eslint-disable-next-line
  //   //   ).sort((a, b) => a._order > b._order ? 1 : -1)
  //   // },
  //   // // defaultGoalCategoryItem(state) {
  //   // //     let defaultGoalCategoryItem = []
  //   // //     defaultGoalCategoryItem = state.configItems.filter(configItem =>
  //   // //         configItem._config === 'Goal Category' &&
  //   // //         configItem.default === true
  //   // //     )
  //   // //     return defaultGoalCategoryItem
  //   // // },
  //   // goalPriorityItems(payload) {
  //   //   return payload.configItems.filter(configItem =>
  //   //     // eslint-disable-next-line
  //   //     configItem._config === 'Goal Priority'
  //   //     // eslint-disable-next-line
  //   //   ).sort((a, b) => a._order > b._order ? 1 : -1)
  //   // },
  //   // // goalStatusItems(payload) {
  //   // //   return payload.configItems.filter(configItem =>
  //   // //     // eslint-disable-next-line
  //   // //     configItem._config === 'Status'
  //   // //   // eslint-disable-next-line
  //   // //   ).sort((a, b) => a._order > b._order ? 1 : -1)
  //   // // },
  //   // statusItems(payload) {
  //   //   return payload.configItems.filter(configItem =>
  //   //     // eslint-disable-next-line
  //   //     configItem._config === 'Status'
  //   //   // eslint-disable-next-line
  //   //   ).sort((a, b) => a._order > b._order ? 1 : -1)
  //   // },
  //   // blogStatusItems(payload) {
  //   //   return payload.configItems.filter(configItem =>
  //   //     // eslint-disable-next-line
  //   //     configItem._config === 'BlogStatus'
  //   //   // eslint-disable-next-line
  //   //   ).sort((a, b) => a._order > b._order ? 1 : -1)
  //   // },
  //   // blogImages(payload) {
  //   //   return payload.configItems.filter(configItem =>
  //   //     // eslint-disable-next-line
  //   //     configItem._config === 'BlogImage'
  //   //   // eslint-disable-next-line
  //   //   ).sort((a, b) => a._id > b._id ? 1 : -1)
  //   // },
  //   // taskTypes(payload) {
  //   //   return payload.configItems.filter(configItem =>
  //   //     // eslint-disable-next-line
  //   //     configItem._config === 'Task Type'
  //   //   // eslint-disable-next-line
  //   //   ).sort((a, b) => a._order > b._order ? 1 : -1)
  //   // },
  //   // goalTypes(payload) {
  //   //   return payload.configItems.filter(configItem =>
  //   //     // eslint-disable-next-line
  //   //     configItem._config === 'Goal Type'
  //   //   // eslint-disable-next-line
  //   //   ).sort((a, b) => a._order > b._order ? 1 : -1)
  //   // },
  //   // fileTypes(payload) {
  //   //   return payload.configItems.filter(configItem =>
  //   //     // eslint-disable-next-line
  //   //     configItem._config === 'File Type'
  //   //   // eslint-disable-next-line
  //   //   ).sort((a, b) => a._order > b._order ? 1 : -1)
  //   // },
  //   // customGoalFieldValues(state) {
  //   //     return state.configItems.filter(configItem =>
  //   //         configItem._config === 'Custom Goal Fields' &&
  //   //         configItem.fieldType === 'List'
  //   //     ).sort((a, b) => a._order > b._order ? 1 : -1)
  //   // }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}