// eslint-disable-next-line import/no-cycle
import { db } from '@/main'
import { format } from 'date-fns'

const state = () => ({
  docs: [],
  doc: {},
  docBeforeUpdate: {},
  selectedDocIdRParam: '', // Router parameter stored value
  docView: 'detailed',
  addDocTag: false,
  docTags: [],
  filterBy: '', // filters in left sidebar
  selectBy: '', // filters in action bar
  docSidebar: false, // used in todo, when clicking on a doc to bring up the doc sidebar
  taskNewDocParams: {}, // pass task's params while adding document
})

const getters = {
};

const actions = {
  async getDocs({ commit, dispatch }, payload) {
    let snapshot = await db.collection('docs')
      .where('zCusId', '==', payload)
      .get();
    let docs = [];
    snapshot.forEach(doc => {
      let appData = doc.data()
      appData.id = doc.id
      docs.push(appData)
    })
    commit('SET_DOCS', docs)
    dispatch('orderTagsFromDocs', docs)
  },
  async getDeltaDocs({ commit, rootState, dispatch }, payload) {
    let snapshot = await db.collection('docs')
      .where('zCusId', '==', payload)
      .where('updatedAt', '>=', rootState.authen.loginTime + '\uf8ff')
      .limit(50)
      .get();
    let deltaDocs = [];
    snapshot.forEach(doc => {
      let appData = doc.data()
      appData.id = doc.id
      deltaDocs.push(appData)
    })
    commit('ADD_DELTADOCS', deltaDocs)

    deltaDocs.forEach(deltaDoc => {
        commit('ADD_DOCTAGS', deltaDoc.tags)
    })
  },
  async addDoc({ commit, dispatch }, payload) {
    await db
        .collection('docs')
        .add({
          name: payload.name,
          description: payload.description,
          type: payload.type,
          fileURL: payload.fileURL,
          fileSize: payload.fileSize,
          iconURL: payload.iconURL,
          owner: payload.owner,
          important: payload.important,
          deleted: payload.deleted,
          version: payload.version,
          tags: payload.tags,
          createdAt: payload.createdAt,
          updatedAt: payload.updatedAt,
          zLastVersion: payload.zLastVersion,
          zCusId: payload.zCusId,
          zParentId: payload.zParentId,
          zParentType: payload.zParentType,
        })
        .then(function (docRef) {
          payload.id = docRef.id
        })
    commit('SET_DOC', payload)
    commit('ADD_TODOCS', payload)
    commit('ADD_DOCTAGS', payload.tags)
    
    payload.instr = 'Add'
    dispatch('packageHistoryItem', payload)
  },
  async updateDoc({ commit, dispatch, state }, payload) {
    await db
      .collection('docs')
      .doc(payload.id)
      .update({
        name: payload.name,
        description: payload.description,
        type: payload.type,
        fileURL: payload.fileURL,
        fileSize: payload.fileSize,
        iconURL: payload.iconURL,
        owner: payload.owner,
        important: payload.important,
        deleted: payload.deleted,
        version: payload.version,
        tags: payload.tags,
        createdAt: payload.createdAt,
        updatedAt: payload.updatedAt,
        zLastVersion: payload.zLastVersion,
        zCusId: payload.zCusId,
        zParentId: payload.zParentId,
        zParentType: payload.zParentType,
      })
    commit('SET_DOC', payload)
    dispatch('orderTagsFromDocs', state.docs)

    if ( !payload.deleted ) {
      payload.instr = 'Update'
    } else {
      payload.instr = 'Remove'
    }
    dispatch('packageHistoryItem', payload)
  },
  async deleteDoc({ state, dispatch }, payload) {
    // delete history item first
    payload.instr = 'Delete'
    dispatch('packageHistoryItem', payload)
    
    let payloadIndex = -1
    await db.collection("docs").doc(payload.id).delete().then(() => {
      // console.log('payload.id: ' + payload.id)
      payloadIndex = state.docs.indexOf(payload)
      // console.log('state.docs.indexOf(payload): ' + payloadIndex)
      if (payloadIndex > -1) {
        state.docs.splice( payloadIndex, 1 )
        // commit('SET_DOC', state.docs[state.docs.length-1])
      }
    }).catch((error) => {
        console.error("Error removing doc: ", error);
    })
    
    dispatch('orderTagsFromDocs', state.docs)
  },
  async packageHistoryItem({ dispatch, rootState }, payload) {

    // 1. prepare historyItem fields (other than 'action')
    let historyItem = {}
    let fuAction = ''
    
    historyItem.module = 'Docment'
    historyItem.moduleId = payload.id
    historyItem.moduleParentId = payload.zParentId
    historyItem.moduleParentType = payload.zParentType
    historyItem.at = format(new Date(payload.createdAt), "yyyy-MM-dd HH:mm")
    historyItem.by = payload.owner
    historyItem.usersNotified = []
    historyItem.zCusId = payload.zCusId

    // 2. prepare 'action' field acc. to payload.instr': 
    //  'Add' / 'Update' / 'Remove' history record        (task / comment / doc / note / blog )
    //  'Like' / 'Heart' / 'Happy' / 'Sad' history record (comment)
    // * only task and comment in notifications           (i.e. 'at' and 'by' fields)
    
    switch ( payload.instr ) {
      case 'Add':
        historyItem.action = 'Add'
        dispatch('history/addHistoryItem', historyItem, { root: true })
        break
      case 'Update':
        historyItem.action = 'Update'
        fuAction = 'Update'
        break
      case 'Remove':
        historyItem.action = 'Remove'
        fuAction = 'Remove'
        break
      case 'Delete':
        historyItem.action = 'Delete'
        fuAction = 'Delete'
    }

    // Proceed if 'Update' or 'Remove' or 'Delete'
    if ( fuAction !== '' ) {
      // Dig out the exiting historyItem from store, grab the id before being updated
      let mappedHistItem = rootState.history.historyItems.find(hItem => hItem.moduleId===payload.id)
      if ( mappedHistItem !== undefined ) {
        historyItem.id = mappedHistItem.id
        if ( fuAction == 'Remove' ) {
          historyItem.at = ''
          historyItem.usersNotified = []
        }
        if ( fuAction=='Update' || fuAction=='Remove' ) {
          dispatch('history/updateHistoryItem', historyItem, { root: true })
        }
        if ( fuAction == 'Delete' ) {
          dispatch('history/deleteHistoryItem', historyItem, { root: true })
        }
      }
    }

    // remove 'instr' field from payload
    delete payload.instr
    delete payload.usersNotified
    fuAction = ''
  },
  setDoc({ commit }, payload) {
    commit('SET_DOC', payload)
  },
  setDocSidebar({ commit }, payload) {
    commit('SET_DOCSIDEBAR', payload)
  },
  setSelectedDocIdRParam({ commit }, payload) {
    commit('SET_SELECTEDDOCIDPARAM', payload)
  },
  setDocBeforeUpdate({ commit }, payload) {
    commit('SET_DOCBEFOREUPDATE', payload)
  },
  setDocs({ commit }, payload) {
    commit('SET_DOCS', payload)
  },
  clearDocs({ commit }) {
    commit('CLEAR_DOCS')
  },
  setSelectedTasks({ commit }, payload) {
    commit('SET_SELECTEDTASKS', payload)
  },
  setSelectedDocIdRParam({ commit }, payload) {
    commit('SET_SELECTEDDOCIDPARAM', payload)
  },
  setDocView({ commit }, payload) {
    commit('SET_DOCVIEW', payload)
  },
  setAddDocTag({ commit }, payload) {
    commit('SET_ADDDOCTAG', payload)
  },
  orderTagsFromDocs({ commit }, payload) {
    // payload is 'docs'
    let docTagsArray = []
    let uniqueArray = []

    // 1. Concat tags from all docs
    payload
      .forEach(doc => docTagsArray = docTagsArray.concat(doc.tags))

    // 2. Dudup docTagsArray
    docTagsArray.forEach(tag => {
      if ( uniqueArray.indexOf(tag)==-1 && tag.trim()!=='' ) {
        uniqueArray.push(tag)
      }
    })

    // // 2. Remove ''
    // docTagsArray = docTagsArray.filter(entry => entry.trim() !== '' && entry!==undefined)
    // docTagsArray = docTagsArray.sort((a, b) => a.localeCompare(b))
    
    // // 3. Remove Duplicates
    // docTagsArray = docTagsArray.filter((item, index) => docTagsArray.indexOf(item) === index)
    // docTagsArray = docTagsArray.reduce(
    //   (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
    //   [],
    // );

    commit('SET_DOCTAGS', uniqueArray)
  },
  setDocTags({ commit }, payload) {
    commit('SET_DOCTAGS', payload)
  },
  setFilterBy({ commit }, payload) {
    commit('SET_FILTERBY', payload)
  },
  setSelectBy({ commit }, payload) {
    commit('SET_SELECTBY', payload)
  },
  setTaskNewDocParams({ commit }, payload) {
    commit('SET_TASKNEWDOCPARAMS', payload)
  },
}

const mutations = {
  SET_SELECTEDDOCIDPARAM(state, payload) {
    state.selectedDocIdRParam = payload
  },
  SET_DOC(state, payload) {
    // 1. Set the payload to state 'task'
    state.doc = payload
    // 2. Update 'task' into the existing "docs" array
    state.docs.forEach(eachDoc => {
      if (eachDoc.id === payload.id) {
        eachDoc.name = payload.name
        eachDoc.description = payload.description
        eachDoc.type = payload.type
        eachDoc.fileURL = payload.fileURL
        eachDoc.fileSize = payload.fileSize
        eachDoc.iconURL = payload.iconURL
        eachDoc.owner = payload.owner
        eachDoc.deleted = payload.deleted
        eachDoc.important = payload.important
        eachDoc.version = payload.version
        eachDoc.tags = payload.tags
        eachDoc.createdAt = payload.createdAt
        eachDoc.updatedAt = payload.updatedAt
        eachDoc.zLastVersion = eachDoc.zLastVersion
        eachDoc.zCusId = eachDoc.zCusId
        eachDoc.zParentId = eachDoc.zParentId
        eachDoc.zParentType = eachDoc.zParentType
      } 
    })
    // state.docs.update
    // console.log('task.js; task is added with id: ' + state.task.id)
  },
  SET_DOCBEFOREUPDATE(state, docBeforeUpdate) {
    state.docBeforeUpdate = docBeforeUpdate
  },
  SET_DOCS(state, payload) {
    state.docs = payload
  },
  ADD_DELTADOCS(state, payload) {
    let isFound = false
    payload.forEach(deltaDoc => {
      state.docs.forEach(doc => {
        if (doc.id === deltaDoc.id) {
          isFound = true
        }
      })
      if (!isFound) {
        state.docs.push(deltaDoc)
      } else {
        // Still have to update the doc!
        let updatedDocIndex = state.docs.findIndex(doc => doc.id == deltaDoc.id)
        state.docs[updatedDocIndex] = deltaDoc
        // state.docs.update
        isFound = false
      }
    })
    // state.docs.update
  },
  CLEAR_DOCS(state) {
    state.doc = {}
    state.docs = []
    state.docBeforeUpdate = {}
    state.selectedDocIdRParam = ''
    state.docView = ''
    state.docTags = []
    state.filterBy = ''
    state.selectBy = ''
  },
  ADD_TODOCS(state, payload) {
    let isDocFound = false
    state.docs.forEach(docc => {
      if (docc.id === payload.id) {
        isDocFound = true
      }
    })
    if (!isDocFound) {
      state.docs.push(payload)
    } 
  },
  SET_SELECTEDDOCIDPARAM(state, payload) {
    state.selectedDocIdRParam = payload
  },
  SET_DOCSIDEBAR(state, payload) {
    state.docSidebar = payload
  },
  SET_DOCVIEW(state, payload) {
    state.docView = payload
  },
  SET_ADDDOCTAG(state, payload) {
    state.addDocTag = payload
  },
  SET_DOCTAGS(state, tags) {
    state.docTags = tags
    // state.docTags.update
  },
  ADD_DOCTAGS(state, payload) {
    payload.forEach(newTag => {
      let newTagIndex = state.docTags.indexOf(newTag)
      if ( newTagIndex==-1 && newTag.trim()!=='' ) {
        state.docTags.push(newTag)
      }
    })
    // let addedTags = []
    // payload.forEach(tag => {
    //   if (tag !== '' && tag !== undefined && !state.docTags.includes(tag)) {
    //     addedTags.push(tag)
    //   }
    //   if (addedTags.length) {
    //     state.docTags = state.docTags.concat(addedTags)
    //   }
    // })
  },
  SET_FILTERBY(state, payload) {
    state.filterBy = payload
  },
  SET_SELECTBY(state, payload) {
    state.selectBy = payload
  },
  SET_TASKNEWDOCPARAMS(state, payload) {
    state.taskNewDocParams = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
