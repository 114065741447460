import { mapState } from 'vuex'
import { format, addMonths } from 'date-fns'

export default {
  name: 'userProfileMixin',
  data() {
    return {
      // isRoleFound: false,
      // roleConfigAdded: false,
      permissionObj: [],
      // isUserProfileLoaded: false,
      // isUsersLoaded: false,
      // isRolesLoaded: false,

      // isRPCLoaded: false, // control loadRPC be run once only
      // isSiteLoaded: false,
      // isNewSiteCreated: false,
    }
  },
  created() {
    if (this.userProfile.id==undefined) {
      this.$store.dispatch('authen/logout')
    }
    if (this.userProfile.disabled) {
      this.$router.push('/pages/miscellaneous/not-authorized')
    }
    if (this.userProfile.zChangePW) {
      this.$router.push('/apps/profile')
    }
  },
  mounted() {
    // geolocation:
    this.getGeolocationInformation(),
    this.$store.dispatch('baseConfig/getBaseConfigArray')
    
    // this.$store.dispatch('stripe/getCheckOutSession')
    // this.$store.dispatch('stripe/checkCheckOutSession')
    // this.$store.dispatch('stripe/checkPayment')
    // this.$store.dispatch('stripe/checkSubscription')
  },
  watch: {
    isUserProfileLoaded() {
      if (this.isUserProfileLoaded) {
        
        // Get Config Items
        this.$store.dispatch('config/getConfigItems2', this.userProfile.zCusId)

        // get users, roles and products
        // console.log('(1) get users, roles and products')
        this.$store.dispatch('authen/get_sys_users', this.userProfile.zCusId)
        // this.isUsersLoaded = true
        this.$store.dispatch('authen/get_sys_roles', this.userProfile.zCusId)
        // this.isRolesLoaded = true
        // this.$store.dispatch('product/getProducts')
        // this.$store.dispatch('stripe/getStripeProducts')
        
        this.$store.dispatch('coupon/getCoupons', this.userProfile.id)

        // get data
        // console.log('(1) get data')
        // this.$store.dispatch('task/getTasks', this.userProfile.zCusId)
        // this.$store.dispatch('task/getTaskSize', this.userProfile.zCusId)
        // this.$store.dispatch('comment/getComments', this.userProfile.zCusId)
        // this.$store.dispatch('channel/getChannels', this.userProfile.zCusId)
        // this.$store.dispatch('response/getResponses', this.userProfile.zCusId)
        this.$store.dispatch('history/getHistoryItems', this.userProfile)
        // this.$store.dispatch('note/getNotes', this.userProfile.zCusId)
        // this.$store.dispatch('blog/getBlogs', this.userProfile.zCusId)
        // this.$store.dispatch('doc/getDocs', this.userProfile.zCusId)

        // get site
        // console.log('(1) get site')
        this.$store.dispatch('site/getSite', this.userProfile.zCusId)
        // this.isSiteLoaded = true

        // Write 'login' log
        // console.log('(1) write "login" log')
        let authItem = {}
        authItem.userName = this.userProfile.userName
        authItem.email = this.userProfile.email
        authItem.title = this.userProfile.title
        authItem.authType = 'login'
        authItem.at = format(new Date(), "yyyy-MM-dd HH:mm:ss")
        if (this.isMobile()) {
          authItem.connection = 'mobile'
        } else {
          authItem.connection = 'web'
        }
        authItem.zCusId = this.userProfile.zCusId
        this.$store.dispatch('histAuth/clearHistAuth')
        // geolocation
        authItem.geoInfo = this.geoData
        this.$store.dispatch('histAuth/addHistAuthItem', authItem)
        
      }
    },
    isSiteLoaded() {
      // console.log('----- this.isSiteLoaded: ' + this.isSiteLoaded)
      // console.log('----- this.site: ' + JSON.stringify(this.site))
      // console.log('----- this.isNewSiteCreated: ' + this.isNewSiteCreated)

      if (this.isSiteLoaded) {
        // if 'this.site' object is empty
        // JSON.stringify(obj) === '{}'
        if ( JSON.stringify(this.site) === undefined && !this.isNewSiteCreated) {
        // if ( Object.keys(this.site).length===0 && !this.isNewSiteCreated) {
          // console.log('(1) Create new site')
          let siteInfo = {}
          siteInfo.cusId = this.userProfile.zCusId
          siteInfo.plan = 'Free' 
          siteInfo.regUser = this.userProfile.id
          siteInfo.regUserEmail = this.userProfile.email
          siteInfo.since = format(new Date(), "yyyy-MM-dd HH:mm:ss")
          // valid for 3 months
          siteInfo.till = format(addMonths(new Date(), 3), "yyyy-MM-dd HH:mm:ss")
          siteInfo.till_storage = format(addMonths(new Date(), 3), "yyyy-MM-dd HH:mm:ss")
          siteInfo.zPartnerCode =  ''
          siteInfo.numOfUsersLimit = '3'
          siteInfo.numOfUsersUsed = '1'
          siteInfo.storageLimit = '50MB'
          siteInfo.storageUsed = '0'
          siteInfo.sub_numTier = '0'
          siteInfo.sub_numStorage = '0'
          siteInfo.sub_isResolved = true
          siteInfo.createdAt = format(new Date(), "yyyy-MM-dd HH:mm:ss")
          siteInfo.updatedAt = format(new Date(), "yyyy-MM-dd HH:mm:ss")
          siteInfo.promoCode = this.userProfile.promoCode
          this.$store.dispatch('site/addSite', siteInfo)
          // this.isNewSiteCreated = true
          this.$store.commit('site/SET_NEWSITECREATED', true)  
        }  
      }
    },
    isUsersLoaded() {
      // if ( !this.isRPCLoaded ) {
      if ( this.isUsersLoaded && this.isRolesLoaded && !this.isRPCLoaded ) {
        // console.log('(1+2) Run loadRPC in isUsersLoaded loop')
        this.loadRPC()
        // this.isRPCLoaded = true
      }
    },
    isRolesLoaded() {
      // if ( !this.isRPCLoaded ) {
      if ( this.isUsersLoaded && this.isRolesLoaded && !this.isRPCLoaded ) {
        // console.log('(1+2) Run loadRPC in isRolesLoaded loop')
        this.loadRPC()
        // this.isRPCLoaded = true
      }
    },
    isCouponsLoaded() {
      if ( this.isCouponsLoaded ) {
        // getMarketerSites, param as every promo code
        this.coupons.forEach(c => {
          // console.log('c.promoCode: ' + c.promoCode)
          this.$store.dispatch('site/getMarketerSites', c.promoCode)
        })
      }
    }
  },
  methods: {
    // Load RPC - Role / Permission / ConfigItems
    loadRPC() {
      let userProfileRoles = []
      let userProfilePermissions = []
      let userProfileTemp = {}
      let basicRole = {}
      let basicConfigItem = {}

      // console.log('this.isUsersLoaded: ' + this.isUsersLoaded)
      // console.log('this.isRolesLoaded: ' + this.isRolesLoaded)      
      // console.log('this.sys_role.length: ' + this.sys_roles.length)

      this.sys_roles.forEach(sys_role => {
        // console.log('sys_role.id: ' + sys_role.id)
        // console.log('user as member? ' + sys_role.members.includes(this.userProfile.id))
        if (sys_role.members.includes(this.userProfile.id)) {
          // this.isRoleFound = true
          this.$store.commit('authen/SET_ISROLEFOUND', true)
          userProfileRoles.push(sys_role.id)
          userProfilePermissions.push(sys_role.permissions)
        }
      })
      // console.log('this.isRoleFound is.....: ' + this.isRoleFound)
      // console.log('this.roleConfigAdded is.....: ' + this.roleConfigAdded)

      if (this.isRoleFound & !this.roleConfigAdded) {

        // console.log('----- is Role Found')
        // Put roles & permissions into userProfile
        let userProfilePermission = this.manyToOnePermission(userProfilePermissions) // consolidated permissions      
        userProfileTemp = this.userProfile
        this.$set(userProfileTemp, 'roles', userProfileRoles)
        this.$set(userProfileTemp, 'permissions', userProfilePermission)        
        this.$store.commit('authen/SET_USERPROFILE', userProfileTemp)

      } else {          
        // console.log('-----is Role NOT Found')

        if (!this.roleConfigAdded) {
          // Create Roles
          basicRole.roleName = 'Basic'
          basicRole.description = 'Default role for Basic Plan Users'
          basicRole.members = []
          basicRole.members.push(this.userProfile.id)
          basicRole.permissions = this.baseConfigArray[0].permissions
          basicRole.zCusId = this.userProfile.zCusId
          this.$store.dispatch('authen/addUserRole', basicRole)
          // console.log('addUserRole done')

          // Put the created roles & config items to userProfile
          userProfileTemp = this.userProfile
          this.$set(userProfileTemp, 'roles', basicRole)
          this.$set(userProfileTemp, 'permissions', basicRole.permissions)        
          this.$store.commit('authen/SET_USERPROFILE', userProfileTemp)

          // Create Config Items
          basicConfigItem.config = this.baseConfigArray[0].config
          basicConfigItem.customFields = this.baseConfigArray[0].customFields
          basicConfigItem.zCusId = this.userProfile.zCusId
          this.$store.dispatch('config/addConfigItem', basicConfigItem)
          // console.log('addConfigItem done')
          // this.roleConfigAdded = true
        }
      }
      
      // Declare the RPC has been loaded
      this.$store.commit('authen/SET_ISRPCLOADED', true)      
    },
    // geolocation
    async getGeolocationInformation() {
      const API_KEY = '90c1a86561c74a52aefb7f25b808173e'
      const API_URL = 'https://ipgeolocation.abstractapi.com/v1/?api_key=' + API_KEY
      // const apiResponse = await fetch(API_URL)   ...................................................
      // this.$store.dispatch('authen/setGeoData', await apiResponse.json())
    },    
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    // permissions => permissionObj
    // permissions: permission set; permission: task/goal, comment, etc.
    manyToOnePermission(permissions) {
      // let permissionObj = []
      // let XLoop = 0
      // let YLoop = 0
      let exit1stLoop = false
      // let exitLoop = false

      // if there is only 1 role (i.e. 1 permission set), return it straight
      // if ( permissions.length == 1 ) {
      //   permissionObj = permissions[0]  // check !!!!!!!!!!!!!!!!!!!!!!
      // } else { 
        permissions.forEach(permission => {
          permission.forEach(permissionItem => {
            if (!exit1stLoop) {
            // if (!this.permissionObj.includes(permissionItem.module)) {
              this.permissionObj.push(permissionItem)
            } 
            else {

              let permissionObj1 = this.permissionObj.find(pObj =>pObj.module === permissionItem.module)

              // if (this.permissionObj.module == permissionItem.module && !exitLoop) {
              // if (!exitLoop) {
                if (permissionObj1.access==false && permissionItem.access==false) {
                  permissionObj1.access = false
                } else {
                  if (!permissionItem.module.startsWith('custom')) {
                    permissionObj1.access = true
                  }
                }
                if (permissionObj1.read==false && permissionItem.read==false) {
                  permissionObj1.read = false
                } else {
                  permissionObj1.read = true
                }
                if (permissionObj1.write==false && permissionItem.write==false) {
                  permissionObj1.write = false
                } else {
                  permissionObj1.write = true
                }
                if (permissionObj1.delete==false && permissionItem.delete==false) {
                  permissionObj1.delete = false
                } else {
                  permissionObj1.delete = true
                }

                // update permissionObj
                this.permissionObj.forEach(permissionObjItem => {
                  if ( permissionObjItem.module == permissionObj1.module ) {
                    permissionObjItem.access = permissionObj1.access
                    permissionObjItem.read = permissionObj1.read
                    permissionObjItem.write = permissionObj1.write
                    permissionObjItem.delete = permissionObj1.delete
                  }
                })
                this.permissionObj.update


                // exitLoop = true
              // }
            }
            // XLoop += 1
          })
          // YLoop += 1
          // XLoop = 0
          
          exit1stLoop = true
        })

      return this.permissionObj
    },
  },
  computed: {
    ...mapState({
      userProfile: state => state.authen.userProfile,
    }),
    ...mapState({
      forceReload: state => state.authen.forceReload,
    }),
    ...mapState({
      isRPCLoaded: state => state.authen.isRPCLoaded,
    }),
    ...mapState({
      isNewSiteCreated: state => state.site.isNewSiteCreated,
    }),
    ...mapState({
      isUserProfileLoaded: state => state.authen.isUserProfileLoaded,
    }),
    ...mapState({
      isUsersLoaded: state => state.authen.isUsersLoaded,
    }),
    ...mapState({
      isRolesLoaded: state => state.authen.isRolesLoaded,
    }),
    ...mapState({
      isEmailVerified: state => state.authen.isEmailVerified,
    }),
    ...mapState({
      sys_users: state => state.authen.sys_users,
    }),
    ...mapState({
      sys_roles: state => state.authen.sys_roles,
    }),
    // geolocation:
    ...mapState({
      geoData: state => state.authen.geoData,
    }),
    ...mapState({
      baseConfigArray: state => state.baseConfig.baseConfigArray,
    }),
    ...mapState({
      roleConfigAdded: state => state.config.roleConfigAdded,
    }),
    ...mapState({
      isSiteLoaded: state => state.site.isSiteLoaded,
    }),
    ...mapState({
      isRoleFound: state => state.authen.isRoleFound,
    }),
    ...mapState({
      site: state => state.site.site,
    }),
    ...mapState({
      coupons: state => state.coupon.coupons,
    }),
    ...mapState({
      isCouponsLoaded: state => state.coupon.isCouponsLoaded,
    }),
  },
}
