// import { db } from '@/main'
export default {
  namespaced: true,

  state: {
    hubView: 'goal',
    hubFilter: 'This Week',
  },

  getters: {
  },

  mutations: {    
    SET_HUBVIEW(state, payload) {
      state.hubView = payload
    },
    SET_HUBFILTER(state, payload) {
      state.hubFilter = payload
    },
  },

  actions: {
    setHubView({ commit }, payload) {
      commit('SET_HUBVIEW', payload)
    },
    setHubFilter({ commit }, payload) {
      commit('SET_HUBFILTER', payload)
    },
  },
}
