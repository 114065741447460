// eslint-disable-next-line import/no-cycle
import { db } from '@/main'
export default {
  namespaced: true,

  state: {
    coupons: [],
    isCouponsLoaded: false,
    coupon: {},
  },

  actions: {
    async getCoupons({ commit }, payload) {
      let snapshot = await db.collection('coupons')
      .where('owner', '==', payload)
      .get()
      let acoupons = [];
      snapshot.forEach(doc => {
        let appData = doc.data()
        appData.id = doc.id
        acoupons.push(appData)
      })
      commit('SET_COUPONS', acoupons)
      commit('SET_ISCOUPONSLOADED', true)
    },

    async addCoupon({ commit }, payload) {
      await db
        .collection('coupons')
        .add({
          promoCode: payload.promoCode,
          source: payload.source,
          sourceLink: payload.sourceLink,
          status: payload.status,
          createdAt: payload.createdAt,
          updatedAt: payload.updatedAt,
          category: payload.category,
          appliedToPlan: payload.appliedToPlan, // e.g. 'Pro'
          appliedToUser: payload.appliedToUser,  // 'all' for marketer, userProfile.id as referee
          owner: payload.owner,
        })
        .then(function (docRef) {
          payload.id = docRef.id
        })
      commit('SET_COUPON', payload)
    },
    
    // For Admin module
    async updateCoupon({ commit }, payload) {
      await db
        .collection('coupons')
        .doc(payload.id)
        .update({
          promoCode: payload.promoCode,
          source: payload.source,
          sourceLink: payload.sourceLink,
          status: payload.status,
          createdAt: payload.createdAt,
          updatedAt: payload.updatedAt,
          category: payload.category,
          appliedToPlan: payload.appliedToPlan, // e.g. 'Pro'
          appliedToUser: payload.appliedToUser,  // 'all' for marketer, userProfile.id as referee
          owner: payload.owner,
        })
      commit('SET_COUPON', payload)
    },
  },

  getters: {},

  mutations: {
    SET_COUPONS(state, payload) {
      state.coupons = payload
    },
    SET_COUPON(state, payload) {
      state.site = payload
    },
    SET_ISCOUPONSLOADED(state, payload) {
      state.isCouponsLoaded = payload
    },
  },

}
