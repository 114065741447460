import { $themeConfig } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: true,
    // isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
  },
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
    },
    SET_VERTICAL_MENU_COLLAPSED(state, payload) {
      state.isVerticalMenuCollapsed = payload
    },
  },
  actions: {
    setVerticalMenu({ commit }, payload) {
      commit('SET_VERTICAL_MENU_COLLAPSED', payload)
    },
  },
}
